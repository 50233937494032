<template>
  <div class="d-flex justify-center align-center h100 ma-12">
    <div class="d-flex justify-center align-center w100">
      <v-card class="login-page">
        <v-row no-gutters>
          <v-col xs="12" sm="12" md="12" lg="6">
            <div class="d-flex justify-center flex-column align-center pa-5">
              <img
                class="logo"
                :src="
                  $vuetify.theme.isDark ? '/img/logo-dark.svg' : '/img/logo.svg'
                "
              />
              <div class="w100">
                <h1 class="title">{{ title }}</h1>
                <p class="text-muted sub-title">
                  {{ subTitle }}
                </p>
                <slot />
              </div>
              <ErrorAlert
                v-for="(error, index) in errors"
                :key="index"
                :error="error"
                :index="index"
              />
            </div>
          </v-col>
          <v-col md="12" lg="6">
            <v-img
              src="/img/login-bg.png"
              class="recovery-img hidden-md"
            ></v-img>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import ErrorAlert from "@/components/ErrorAlert";
import { mapState } from "vuex";

export default {
  name: "SignComponent",
  components: {
    ErrorAlert,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.error.errors,
    }),
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 992px) {
  .hidden-md {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.login-titles h1,
.login-titles p {
  text-align: center;
}
</style>
